*{
    margin:0; 
    padding:0; 
}

.navBar{
    background-color: #0B0E11;
}

.navLink{
    transition: background-color 0.5s ease-in-out;
    padding-left: 10px;
}

.navLink:hover{
    background-color: #212529;
    color:white
}

 .dropdown-menu{
    background-color: #0B0E11;
 }

 .dropdown-menu .navLink{
    color: white;
 }

 .dropdown-menu .navLink:hover{
    background-color: #212529;
    color:white
}