@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

:root {
    --black-with-opacity: rgba(0, 0, 0, 0.507); /* Declare a variable */
  }



/* this is the landing page div */

.landing_page{
    width: 100%;
    height: 60vh;
    background-image: url("../../images/bg/bg_image.png") ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

/* this is the title  div */

.title_div{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: var(--black-with-opacity);
    transition: all 1s ease;
}

/* this is the paragraphe inside the title div */
.title_div p{
    width: 70%;
    font-weight: 700;
    font-style: normal;
    font-size: 3.5rem;
    line-height: 80px;
    color: white;
    font-family: 'Open Sans', sans-serif;
    animation:tracking-in-expand-fwd-top .8s cubic-bezier(.215,.61,.355,1.000) both
}

/* this is the register div */

.register{
    width: 70%;
    margin: 0 auto;
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* this is for connections div */
.connections{
    width: 70%;
    padding: 40px;
    background-image: url("../../images/bg/connections.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
}

.title_connections{
    width: 100%;
    padding-left: 20px;
}
.title_connections p{
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
    line-height: 80px;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

@keyframes tracking-in-expand-fwd-top{0%{letter-spacing:-.5em;transform:translateZ(-700px) translateY(-500px);opacity:0}40%{opacity:.6}100%{transform:translateZ(0) translateY(0);opacity:1}}