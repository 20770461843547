/* this is for small screens */

@media screen and (max-width: 500px) {
    .title_div{
        padding: 40px;
    }
    .title_div p{
        width: 80%;
        font-size: 2rem;
        line-height: 50px;
    }
    .connections{
        width: 100%;
        border-radius: 0;
        margin-top: 10px;
        padding: 0px;
    }
    
}

@media screen and (min-width: 500px) and (max-width: 700px) {
    .title_div{
        padding: 40px;
    }
    .title_div p{
        width: 80%;
        font-size: 3rem;
        line-height: 60px;
    }
    .connections{
        width: 100%;
        border-radius: 0;
        margin-top: 10px;
    }
    
}

@media screen and (min-width: 700px) and (max-width: 900px) {
    .title_div{
        padding: 40px;
    }
    .title_div p{
        width: 80%;
        font-size: 3rem;
        line-height: 60px;
    }
    .connections{
        width: 90%;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    .title_div{
        padding: 40px;
    }
    .title_div p{
        width: 80%;
        font-size: 3rem;
        line-height: 60px;
    }
    .title_div{
        padding: 40px;
    }
    .title_div p{
        width: 80%;
        font-size: 3rem;
        line-height: 60px;
    }
    .connections{
        width: 90%;
    }
}

@media screen and (min-width: 1300px)  {
    .title_div p{
        width: 60%;
        font-size: 4rem;
        line-height: 80px;
    }
}