
@media screen and (max-width: 501px) {
    .connection_parent .logos_image{
        width: 60px;
    }
    .connection_parent{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px;
    }
    .connection_parent_parent{
        padding: 0px;
    }
}

@media screen and (min-width: 500px) and (max-width: 700px) {
    .connection_parent .logos_image{
        width: 100px;
    }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
    .connection_parent .logos_image{
        width: 100px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
   
}